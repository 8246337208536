import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '../../../styled-system/css'
export default function ResponsiveImage({
  imageName,
  className,
  background,
  imageAlt
}: {
  imageName: string
  className?: string
  background?: string
  imageAlt?: string
}) {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
          original {
            src
          }
        }
      }
    }
  `)

  const imageNode = data.allImageSharp.nodes.find(
    (
      node // eslint-disable-line
    ) => node.original.src.includes(imageName)
  )
  if (!imageNode) {
    return null
  }

  return (
    <div
      className={
        className ? className : css({ width: '300px', height: '350px' })
      }
    >
      <GatsbyImage
        className={
          className
            ? `${css({ width: '100%', height: '100%', background: background ? background : 'inherit' })} ${className}`
            : css({ width: '300px', height: '350px' })
        }
        image={imageNode.gatsbyImageData}
        alt={imageAlt ?? ''}
      />
    </div>
  )
}
