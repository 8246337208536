import React from 'react'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { css } from '../../../styled-system/css'
import { HeadFC, navigate } from 'gatsby'
import ResponsiveImage from '../../components/photographie/ResponsiveImage'
import SEO from '../../components/SEO'

export default function Apropos() {
  return (
    <HeaderAndFooterLayout>
      <div
        className={css({
          display: 'flex',
          flexDir: 'column',
        })}
      >
        <div
          className={css({
            overflow: 'hidden',
            width: '100%',
            aspectRatio: '1.5',
            position: 'relative',
            borderBottom: 'solid 8px #F2D98D',
          })}
        >
          <h1
            className={css({
              position: 'absolute',
              color: 'white',
              width: '80%',
              textAlign: 'center',
              fontSize: '34px',
              fontWeight: 'bold',
              zIndex: '1',
              top: '60%',
              left: '0%',
              '@media (max-width: 768px)': {
                left: '0%',
                top: '60%',
                fontSize: '24px',
                width: '100%',
              },
            })}
          >
            Inna <br />
            Votre photographe professionnelle
          </h1>
          <div
            className={css({
              display: 'flex',
              flexDir: 'row',
              height: '100%',
            })}
          >
            <div className={css({ width: '100%' })}>
              <ResponsiveImage
                className={css({
                  objectFit: 'cover',
                  height: '100%',
                })}
                imageName="inna"
              />
            </div>
            <div
              className={css({ width: '0%', backgroundColor: '#020202' })}
            ></div>
          </div>
        </div>
        {/*------------------Content--------------------*/}
        <div
          className={css({
            width: '100%',
            maxWidth: '75rem',
            margin: '1rem auto 0',
          })}
        >
          {/*--------------------------- flex 1 ----------------------*/}
          <div
            className={css({
              display: 'flex',
              flexDir: 'row',
              gap: '5%',
              width: '100%',
              alignItems: 'center',
              margin: '50px auto 0',
              '@media (max-width: 768px)': { flexDir: 'column' },
            })}
          >
            <h2
              className={css({
                textAlign: 'center',
                fontSize: '32px',
                '@media (max-width: 768px)': { fontSize: '25px' },
              })}
            >
              Et si nous faisons connaissances ?<br></br>Je me présente à vous !
            </h2>
            <ResponsiveImage
              className={css({
                maxWidth: '575px',
                borderRadius: '62px',
                width: '40%',
                '@media (max-width: 768px)': { width: '100%', margin: 'auto' },
              })}
              imageName="AproposA"
            />
          </div>
          {/*--------------------------- flex 2 ----------------------*/}
          <div
            className={css({
              display: 'flex',
              flexDir: 'row',
              justifyItems: 'center',
              gap: '5%',
              alignItems: 'center',
              margin: '50px auto 0',
              '@media (max-width: 768px)': { flexDir: 'column' },
            })}
          >
            <div
              className={css({
                display: 'flex',
                flexDir: 'row',
                '@media (max-width: 768px)': { width: '100%' },
              })}
            >
              <ResponsiveImage
                className={css({
                  width: '400px',
                  borderRadius: '0 62px 0 0',
                  objectFit: 'cover',
                  '@media (max-width: 768px)': {
                    width: '100%',
                    margin: 'auto',
                    borderRadius: '62px 62px 0 0',
                  },
                })}
                imageName="Apropos2"
              />
              <ResponsiveImage
                className={css({
                  width: '400px',
                  height: '505px',
                  borderRadius: '62px 62px 62px 0',
                  objectFit: 'cover',
                  '@media (max-width: 768px)': { display: 'none' },
                })}
                imageName="Apropos3"
              />
            </div>
            <p
              className={css({
                lineHeight: '22px',
                fontSize: '20px',
                fontWeight: '400px',
                '@media (max-width: 768px)': {
                  lineHeight: '22px',
                  fontSize: '20px',
                  fontWeight: '400px',
                  width: '90%',
                  marginTop: '50px',
                  textAlign: 'center',
                },
              })}
            >
              Je suis Inna, votre photographe passionnée, spécialisée dans la
              création de souvenirs intimes et précieux à travers une variété de
              moments uniques de la vie. Avec mon objectif comme plume, je tisse
              des récits visuels qui transcendent le temps, figeant
              l&apos;émotion et la beauté dans une image. Dans le cadre de mon
              travail, je me consacre à plusieurs domaines, chacun étant une
              histoire à raconter à travers l&apos;objectif de mon appareil
              photo. Des instants fugaces aux moments les plus mémorables, je
              suis là pour immortaliser chaque sourire, chaque larme, chaque
              éclat de joie, transformant des fragments de temps en souvenirs
              éternels.
            </p>
          </div>
          {/*--------------------------- flex 3 ----------------------*/}
          <div
            className={css({
              display: 'flex',
              flexDir: 'row',
              justifyItems: 'center',
              gap: '10%',
              alignItems: 'center',
              margin: '50px auto 0',
              '@media (max-width: 768px)': { flexDir: 'column', gap: '50px' },
            })}
          >
            <h2
              className={css({
                textAlign: 'center',
                fontSize: '32px',
                '@media (max-width: 768px)': { fontSize: '25px' },
              })}
            >
              Commençons par la maternité !
            </h2>
            <div
              className={css({
                display: 'flex',
                flexDir: 'row',
                alignItems: 'flex-end',
                '@media (max-width: 768px)': { width: '100%' },
              })}
            >
              <ResponsiveImage
                className={css({
                  width: '400px',
                  borderRadius: '62px 0 0 0',
                  objectFit: 'cover',
                  height: '500px',
                  '@media (max-width: 768px)': {
                    width: '100%',
                    margin: 'auto',
                    borderRadius: '62px',
                    aspectRatio: '1.5',
                  },
                })}
                imageName="Apropos4"
              />
              <ResponsiveImage
                className={css({
                  width: '400px',
                  borderRadius: '62px 62px 62px 0',
                  objectFit: 'cover',
                  height: '600px',
                  '@media (max-width: 768px)': { display: 'none' },
                })}
                imageName="Apropos5"
              />
            </div>
          </div>
          {/*----------------------------------flex 4----------------*/}
          <div
            className={css({
              display: 'flex',
              flexDir: 'row',
              justifyItems: 'center',
              gap: '10%',
              alignItems: 'center',
              margin: '50px auto 0',
              '@media (max-width: 768px)': { flexDir: 'column' },
            })}
          >
            <div
              className={css({
                width: '550px',
                '@media (max-width: 768px)': { width: '100%' },
              })}
            >
              <ResponsiveImage
                className={css({
                  width: '100%',
                  borderRadius: '0 62px 62px 0',
                  objectFit: 'cover',
                  aspectRatio: '1',
                  '@media (max-width: 768px)': {
                    width: '100%',
                    margin: 'auto',
                    borderRadius: '62px 62px 0 0',
                  },
                })}
                imageName="Apropos6"
              />
            </div>
            <div
              className={css({
                alignItems: 'center',
                width: '50rem',
                display: 'flex',
                flexDir: 'column',
                '@media (max-width: 768px)': { width: '90%' },
              })}
            >
              <p
                className={css({
                  lineHeight: '22px',
                  fontSize: '20px',
                  fontWeight: '400px',
                  '@media (max-width: 768px)': {
                    lineHeight: '22px',
                    fontSize: '20px',
                    fontWeight: '400px',
                    width: '90%',
                    marginTop: '50px',
                    textAlign: 'center',
                  },
                })}
              >
                Les séances de grossesse sont un moment magique où je
                m&apos;efforce de capturer la beauté, la grâce et la force de la
                future maman alors qu&apos;elle porte en elle une nouvelle vie.
                Chaque cliché est une célébration de cette période spéciale, une
                ode à la féminité et à la puissance de la création. Mon objectif
                devient un pinceau qui peint avec la lumière, mettant en valeur
                chaque courbe, chaque émotion, chaque expression de joie et
                d&apos;anticipation. Ces séances ne sont pas seulement des
                sessions photo, mais des voyages intimes où nous honorons la
                connexion profonde entre une mère et son enfant à venir,
                capturant la magie de la maternité pour l&apos;éternité.
              </p>
            </div>
          </div>
          {/*----------------------------------flex 5----------------*/}
          <h2
            className={css({
              textAlign: 'center',
              fontSize: '32px',
              margin: '50px auto 0',
            })}
          >
            Les premiers jours de vie dun nouveau-né
          </h2>
          <div
            className={css({
              display: 'flex',
              flexDir: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '50px auto 0',
            })}
          >
            <ResponsiveImage
              className={css({
                maxWidth: '1100px',
                width: '90%',
                borderRadius: '62px 62px 62px 62px',
                objectFit: 'cover',
                aspectRatio: '1.5',
                margin: 'auto',
                '@media (max-width: 768px)': { width: '100%' },
              })}
              imageName="Apropos7"
            />

            <p
              className={css({
                lineHeight: '22px',
                fontSize: '20px',
                fontWeight: '400px',
                width: '60%',
                marginTop: '50px',
                textAlign: 'center',
                '@media (max-width: 768px)': { width: '100%' },
              })}
            >
              C&apos;est un honneur pour moi de documenter les premiers
              souffles, les petits orteils et les doux sourires de ces nouveaux
              arrivants dans le monde. Chaque cliché est empreint de douceur et
              de tendresse, capturant la pureté et l&apos;innocence qui
              caractérisent cette période si précieuse. À travers mon objectif,
              je saisis les moments fugaces et magiques qui marquent le début de
              leur voyage dans ce monde. Chaque clic d&apos;obturateur devient
              une fenêtre ouverte sur l&apos;amour inconditionnel et la joie
              pure que ces précieux instants apportent à leur famille. Ces
              photographies deviennent des trésors intemporels, témoins de
              l&apos;amour infini qui entoure ces nouveaux venus.
            </p>
          </div>
          {/*------------------------------------Divider-----------------------------------------------*/}
          {/*--------------------------- flex 1 ----------------------*/}
          <div
            className={css({
              display: 'flex',
              flexDir: 'row',
              justifyContent: 'center',
              gap: '5%',
              alignItems: 'center',
              margin: '50px auto 0',
              '@media (max-width: 768px)': { flexDir: 'column' },
            })}
          >
            <h2 className={css({ textAlign: 'center', fontSize: '32px' })}>
              Puis, les bébés assis
            </h2>
            <ResponsiveImage
              className={css({
                maxWidth: '575px',
                width: '100%',
                borderRadius: '62px',
              })}
              imageName="Apropos8"
            />
          </div>
          {/*--------------------------- flex 2 ----------------------*/}
          <div
            className={css({
              display: 'flex',
              flexDir: 'row',
              justifyItems: 'center',
              gap: '5%',
              alignItems: 'center',
              margin: '50px auto 0',
              '@media (max-width: 768px)': { flexDir: 'column' },
            })}
          >
            <div
              className={css({
                display: 'flex',
                flexDir: 'row',
                alignItems: 'flex-end',
                '@media (max-width: 768px)': { width: '100%' },
              })}
            >
              <ResponsiveImage
                className={css({
                  width: '400px',
                  height: '500px',
                  borderRadius: '0 62px 0 0',
                  objectFit: 'cover',
                  aspectRatio: '1',
                  '@media (max-width: 768px)': {
                    width: '100%',
                    margin: 'auto',
                    borderRadius: '62px 62px 0 0',
                  },
                })}
                imageName="Apropos9"
              />
              <ResponsiveImage
                className={css({
                  width: '400px',
                  aspectRatio: '1',
                  borderRadius: '62px 62px 62px 0',
                  objectFit: 'cover',
                  '@media (max-width: 768px)': { display: 'none' },
                })}
                imageName="Apropos10"
              />
            </div>
            <p
              className={css({
                lineHeight: '22px',
                fontSize: '20px',
                fontWeight: '400px',
                '@media (max-width: 768px)': {
                  lineHeight: '22px',
                  fontSize: '20px',
                  fontWeight: '400px',
                  width: '90%',
                  marginTop: '50px',
                  textAlign: 'center',
                },
              })}
            >
              Les bébés assis représentent une autre étape importante dans la
              vie de votre enfant. À ce stade, ils commencent à explorer le
              monde qui les entoure avec curiosité et émerveillement. Mes
              séances sont conçues pour capturer ces moments de découverte et de
              joie, figeant dans le temps les expressions espiègles et les
              petits rires qui remplissent votre cœur de bonheur. Chaque cliché
              est un témoignage de leur éveil à de nouvelles sensations, de leur
              énergie débordante et de leur tendresse infinie. Avec mon objectif
              comme témoin, nous immortalisons ces instants précieux,
              transformant chaque séance photo en un voyage magique à travers
              leur monde en expansion.
            </p>
          </div>
          {/*--------------------------- flex 3 ----------------------*/}
          <div
            className={css({
              display: 'flex',
              flexDir: 'row',
              justifyItems: 'center',
              gap: '5%',
              alignItems: 'center',
              margin: '50px auto 0',
              '@media (max-width: 768px)': { flexDir: 'column' },
            })}
          >
            <h2 className={css({ textAlign: 'center', fontSize: '32px' })}>
              Vient le temps des portraits de famille et de couple
            </h2>
            <div
              className={css({
                display: 'flex',
                flexDir: 'row',
                alignItems: 'flex-end',
                '@media (max-width: 768px)': { width: '100%' },
              })}
            >
              <ResponsiveImage
                className={css({
                  width: '400px',
                  borderRadius: '62px 62px 0 62px',
                  objectFit: 'cover',
                  aspectRatio: '1',
                  '@media (max-width: 768px)': {
                    width: '100%',
                    margin: 'auto',
                    borderRadius: '62px',
                    aspectRatio: '1.5',
                  },
                })}
                imageName="Apropos11"
              />
              <ResponsiveImage
                className={css({
                  width: '400px',
                  borderRadius: '62px 62px 62px 0',
                  objectFit: 'cover',
                  height: '600px',
                  '@media (max-width: 768px)': { display: 'none' },
                })}
                imageName="Apropos12"
              />
            </div>
          </div>
          {/*-------------------------flex 4 --------------------*/}
          <div
            className={css({
              display: 'flex',
              flexDir: 'row',
              justifyItems: 'center',
              gap: '10%',
              alignItems: 'center',
              margin: '3.15rem auto 0',
              '@media (max-width: 768px)': {
                flexDir: 'column',
                gap: '3.125rem',
              },
            })}
          >
            <div
              className={css({
                width: '34.375rem',
                '@media (max-width: 768px)': { width: '100%' },
              })}
            >
              <ResponsiveImage
                className={css({
                  width: '100%',
                  borderRadius: '0 62px 62px 0',
                  objectFit: 'cover',
                  height: '500px',
                  '@media (max-width: 768px)': {
                    width: '100%',
                    margin: 'auto',
                    borderRadius: '62px 62px 0 0',
                  },
                })}
                imageName="Apropos13"
              />
            </div>
            <div
              className={css({
                alignItems: 'center',
                width: '50rem',
                display: 'flex',
                flexDir: 'column',
                '@media (max-width: 768px)': { width: '90%' },
              })}
            >
              <p
                className={css({
                  lineHeight: '1.5rem',
                  fontSize: '1.25rem',
                  fontWeight: '25rem',
                  '@media (max-width: 768px)': {
                    lineHeight: '22px',
                    fontSize: '20px',
                    fontWeight: '25rem',
                    width: '90%',
                    marginTop: '50px',
                    textAlign: 'center',
                    margin: 'auto',
                  },
                })}
              >
                Ces séances sont l&apos;occasion parfaite de capturer les liens
                qui vous unissent, les moments de complicité et d&apos;amour
                partagés avec vos proches. Chaque photo raconte une histoire,
                celle de votre famille, de votre amour, de votre bonheur. Je ne
                suis pas seulement là pour capturer les moments intimes. En tant
                que photographe professionnelle, je suis également là pour vous
                aider à mettre en valeur votre image. Que ce soit pour un
                portrait corporatif ou un portrait artistique, je m&apos;engage
                à capturer votre essence avec élégance et authenticité.
              </p>
            </div>
          </div>
          {/*-----------------------flex 5---------------------*/}
          <h2
            className={css({
              textAlign: 'center',
              fontSize: '32px',
              margin: '50px auto 0',
            })}
          >
            Mais aussi les cérémonies
          </h2>
          <div
            className={css({
              display: 'flex',
              flexDir: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '50px auto 0',
            })}
          >
            <ResponsiveImage
              className={css({
                maxWidth: '1100px',
                width: '90%',
                borderRadius: '62px 62px 62px 62px',
                objectFit: 'cover',
                aspectRatio: '1.5',
                margin: 'auto',
                '@media (max-width: 768px)': { width: '100%' },
              })}
              imageName="Apropos14"
            />

            <p
              className={css({
                lineHeight: '22px',
                fontSize: '20px',
                fontWeight: '400px',
                width: '60%',
                marginTop: '50px',
                textAlign: 'center',
                '@media (max-width: 768px)': { width: '90%' },
              })}
            >
              Les cérémonies de baptême et les événements familiaux sont
              également des moments que je considère comme précieux. En
              documentant ces occasions spéciales, je m&apos;efforce de capturer
              chaque sourire, chaque émotion partagée, afin de créer des
              souvenirs qui resteront gravés dans votre cœur pour les années à
              venir. Mon objectif devient le témoin silencieux de ces moments
              sacrés, immortalisant non seulement les rituels et les traditions,
              mais aussi les liens familiaux profonds et l&apos;amour qui les
              entoure. Chaque image raconte une histoire unique, révélant la
              beauté des moments simples mais significatifs qui tissent la toile
              de votre histoire familiale.
            </p>
            <button
              className={css({
                backgroundColor: '#D9C58B',
                border: 'none',
                color: 'white',
                fontWeight: 'bold',
                padding: '15px 32px',
                width: '300px',
                textAlign: 'center',
                textDecoration: 'none',
                textTransform: 'uppercase',
                fontSize: '16px',
                margin: '4px 2px',
                cursor: 'pointer',
                borderRadius: '4px',
                transition: 'backgroundColor 0.2s',
                marginTop: '100px',
                marginBottom: '4rem',
                _hover: {
                  backgroundColor: '#CCB983',
                },
              })}
              onClick={() => navigate('/photographie/galerie/')}
            >
              Voir mes photos
            </button>
          </div>
        </div>
      </div>
    </HeaderAndFooterLayout>
  )
}

export const Head: HeadFC = () => (
  <SEO
    title="À propos de Digit Universe - Inna, Photographe Passionnée à Lille"
    description="Rencontrez Inna, photographe passionnée chez Digit Universe à Lille. Spécialisée en photographie de grossesse, nouveau-nés, familles et événements, elle capture des moments intimes et précieux avec élégance et authenticité."
    robotsContent="index, follow"
  />
)
